export function Logo(props: JSX.IntrinsicElements["svg"]) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
    width="733.000000pt" height="701.000000pt" viewBox="0 0 733.000000 701.000000"
    preserveAspectRatio="xMidYMid meet">

    <g transform="translate(0.000000,701.000000) scale(0.100000,-0.100000)"
    fill="#000000" stroke="none">
    <path d="M2397 6802 l-197 -197 0 -75 0 -75 200 -200 200 -200 200 200 200
    200 0 75 0 75 -198 198 c-108 108 -199 197 -202 197 -3 0 -94 -89 -203 -198z"/>
    <path d="M4442 6637 l-252 -252 -1 -45 0 -45 -252 258 c-139 141 -257 257
    -262 257 -6 0 -155 -145 -333 -323 l-322 -322 0 -75 0 -75 275 -275 275 -275
    -147 -147 -148 -148 -51 51 c-45 45 -52 48 -64 34 -12 -14 -20 -9 -74 44 l-61
    61 -430 -430 -430 -430 93 -93 92 -92 -310 -310 -310 -310 175 -175 175 -175
    -310 -310 -310 -310 -248 248 -247 247 -258 -258 -257 -257 0 -75 0 -75 258
    -258 257 -257 258 258 257 257 0 70 0 70 100 -100 100 -100 -237 -237 -236
    -236 63 -114 c159 -288 293 -458 657 -834 342 -353 500 -484 786 -653 949
    -558 2156 -555 3104 7 704 417 1215 1096 1413 1877 72 284 94 464 93 775 0
    251 -13 395 -58 615 -95 471 -309 923 -622 1312 -101 126 -575 616 -689 712
    -162 138 -489 361 -528 361 -6 0 -115 -103 -241 -230 l-230 -230 -66 66 c-59
    58 -69 65 -87 55 -29 -16 -39 4 -15 32 107 125 410 453 420 455 9 3 13 25 13
    76 l0 73 -251 256 c-138 142 -256 257 -262 257 -7 0 -126 -114 -265 -253z m81
    -737 l106 -95 -272 -272 -272 -273 -122 122 c-106 105 -124 120 -138 108 -8
    -7 -19 -10 -22 -6 -4 4 111 125 255 269 237 238 262 265 262 295 l0 34 49 -44
    c27 -24 96 -86 154 -138z m18 -1131 c281 -47 611 -194 780 -346 115 -103 274
    -439 323 -683 61 -300 41 -631 -54 -906 -45 -130 -150 -336 -225 -439 -332
    -462 -841 -726 -1400 -726 -360 0 -672 94 -965 292 -118 80 -131 93 -190 184
    -112 174 -198 387 -242 607 -20 98 -23 142 -22 328 0 184 3 230 23 320 86 399
    288 741 585 989 222 185 492 315 769 370 138 28 139 28 328 30 128 1 195 -4
    290 -20z"/>
    <path d="M252 6187 l-252 -252 0 -75 0 -75 258 -258 257 -257 258 258 257 257
    0 75 0 75 -253 253 c-138 138 -257 252 -262 252 -6 0 -124 -114 -263 -253z"/>
    <path d="M1517 6002 l-437 -437 0 -75 0 -75 435 -435 435 -435 435 435 435
    435 0 80 0 80 -433 433 -432 432 -438 -438z"/>
    <path d="M867 4632 l-307 -307 0 -80 0 -80 308 -308 307 -307 313 313 312 312
    0 71 0 71 -306 311 c-168 172 -309 312 -312 312 -4 0 -145 -138 -315 -308z"/>
    <path d="M237 3772 l-197 -197 0 -75 0 -75 200 -200 200 -200 200 200 200 200
    0 75 0 75 -198 198 c-108 108 -199 197 -202 197 -3 0 -94 -89 -203 -198z"/>
    </g>
    </svg>
  );
}

export function LogoIcon(props: JSX.IntrinsicElements["svg"]) {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 733.000000 701.000000"
    preserveAspectRatio="xMidYMid meet"
    {...props} >

    <g transform="translate(0.000000,701.000000) scale(0.100000,-0.100000)"
    fill="#000000" stroke="none">
    <path d="M2397 6802 l-197 -197 0 -75 0 -75 200 -200 200 -200 200 200 200
    200 0 75 0 75 -198 198 c-108 108 -199 197 -202 197 -3 0 -94 -89 -203 -198z"/>
    <path d="M4442 6637 l-252 -252 -1 -45 0 -45 -252 258 c-139 141 -257 257
    -262 257 -6 0 -155 -145 -333 -323 l-322 -322 0 -75 0 -75 275 -275 275 -275
    -147 -147 -148 -148 -51 51 c-45 45 -52 48 -64 34 -12 -14 -20 -9 -74 44 l-61
    61 -430 -430 -430 -430 93 -93 92 -92 -310 -310 -310 -310 175 -175 175 -175
    -310 -310 -310 -310 -248 248 -247 247 -258 -258 -257 -257 0 -75 0 -75 258
    -258 257 -257 258 258 257 257 0 70 0 70 100 -100 100 -100 -237 -237 -236
    -236 63 -114 c159 -288 293 -458 657 -834 342 -353 500 -484 786 -653 949
    -558 2156 -555 3104 7 704 417 1215 1096 1413 1877 72 284 94 464 93 775 0
    251 -13 395 -58 615 -95 471 -309 923 -622 1312 -101 126 -575 616 -689 712
    -162 138 -489 361 -528 361 -6 0 -115 -103 -241 -230 l-230 -230 -66 66 c-59
    58 -69 65 -87 55 -29 -16 -39 4 -15 32 107 125 410 453 420 455 9 3 13 25 13
    76 l0 73 -251 256 c-138 142 -256 257 -262 257 -7 0 -126 -114 -265 -253z m81
    -737 l106 -95 -272 -272 -272 -273 -122 122 c-106 105 -124 120 -138 108 -8
    -7 -19 -10 -22 -6 -4 4 111 125 255 269 237 238 262 265 262 295 l0 34 49 -44
    c27 -24 96 -86 154 -138z m18 -1131 c281 -47 611 -194 780 -346 115 -103 274
    -439 323 -683 61 -300 41 -631 -54 -906 -45 -130 -150 -336 -225 -439 -332
    -462 -841 -726 -1400 -726 -360 0 -672 94 -965 292 -118 80 -131 93 -190 184
    -112 174 -198 387 -242 607 -20 98 -23 142 -22 328 0 184 3 230 23 320 86 399
    288 741 585 989 222 185 492 315 769 370 138 28 139 28 328 30 128 1 195 -4
    290 -20z"/>
    <path d="M252 6187 l-252 -252 0 -75 0 -75 258 -258 257 -257 258 258 257 257
    0 75 0 75 -253 253 c-138 138 -257 252 -262 252 -6 0 -124 -114 -263 -253z"/>
    <path d="M1517 6002 l-437 -437 0 -75 0 -75 435 -435 435 -435 435 435 435
    435 0 80 0 80 -433 433 -432 432 -438 -438z"/>
    <path d="M867 4632 l-307 -307 0 -80 0 -80 308 -308 307 -307 313 313 312 312
    0 71 0 71 -306 311 c-168 172 -309 312 -312 312 -4 0 -145 -138 -315 -308z"/>
    <path d="M237 3772 l-197 -197 0 -75 0 -75 200 -200 200 -200 200 200 200 200
    0 75 0 75 -198 198 c-108 108 -199 197 -202 197 -3 0 -94 -89 -203 -198z"/>
    </g>
    </svg>
  );
}
