export async function createChatMessage(
    chat: object,
    context: string,
    chatId: string,
    callBack: Function
  ) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      var raw = JSON.stringify({
          "chat": chat,
          "model": "gpt-4",
          "context": context,
          "conversation": chatId,
        });
      
  
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw
      };
  
      const response:any = await fetch("http://localhost:3000" + "/chat", requestOptions)
      const reader =  response.body.getReader();
  
      var contentMsg = ""
      
      const decoder = new TextDecoder()
      
      while(true) {
          const { done, value } = await reader.read();
  
          const textChunk = decoder.decode(value);
          const regex = /data: ({.*?})/g;
          let match;
          while ((match = regex.exec(textChunk)) !== null) {
              const jsonData = match[1];
              const jsonObject = JSON.parse(jsonData);
              
              var contentMsg = contentMsg + jsonObject.text;
              callBack(contentMsg, done)
          }
  
          
          if (done) {
              callBack(contentMsg, done)
              break;
          }
      }
  
      return true;
  
  }
  